import React from "react";
import Layout from "../components/layout/layout";
import FAQs from "../components/page-components/faq/faq";

const Faq = () => {
  return (
    <Layout>
      <FAQs />
    </Layout>
  );
};

export default Faq;
